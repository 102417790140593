<div class="p-4 overflow-auto h-screen w-full">

    <div class="px-2 mt-2">
        <button class="btn-inverse py-1" (click)="goBack()">< Back</button>
    </div>

    
    <div class="border-solid border-2 border-grey-500 mb-3 mt-3 mx-1"></div>
	

    <h1>{{audit.AuditName}}</h1>

    <div *ngIf="reloading">
        <p>Loading...</p>
    </div>

    <div *ngIf="!reloading">
        <div class="flex lg:flex-row flex-col mt-8">
            <div class="w-52 border p-2 rounded-tl-md lg:rounded-tr-none rounded-tr-md lg:rounded-bl-md cursor-pointer text-center" [ngClass]="{'border-green-300': showMainInfo, 'border-grey-900': !showMainInfo, 'shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px]': showMainInfo}" (click)="showAuditMainInfo()"><span class="p-4" [ngClass]="{'text-green-700':showMainInfo, 'font-bold': showMainInfo}">Main Information</span></div>
            <div class="w-52 border border-grey-900 p-2 cursor-pointer text-center" [ngClass]="{'border-green-300': showEntryOptions, 'border-grey-900': !showEntryOptions, 'shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px]': showEntryOptions}" (click)="showAuditEntryOptions()"><span class="p-4" [ngClass]="{'text-green-700':showEntryOptions, 'font-bold': showEntryOptions}">Entry Options</span></div>
            <div class="w-52 border border-grey-900 p-2 cursor-pointer text-center" [ngClass]="{'border-green-300': showQuestions, 'border-grey-900': !showQuestions, 'shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px]': showQuestions}" (click)="showAuditQuestions()"><span class="p-4" [ngClass]="{'text-green-700':showQuestions, 'font-bold': showQuestions}">Questions</span></div>
            <div class="w-52 border border-grey-900 p-2 lg:rounded-tr-md lg:rounded-bl-none rounded-bl-md rounded-br-md cursor-pointer text-center" [ngClass]="{'border-green-300': showWorkflow, 'border-grey-900': !showWorkflow, 'shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px]': showWorkflow}" (click)="showAuditWorkflow()"><span class="p-4"[ngClass]="{'text-green-700':showWorkflow, 'font-bold': showWorkflow}">Workflow</span></div>
        </div>
    
        <app-edit-main-info *ngIf="showMainInfo" [(audit)]="audit" [auditTypes]="auditTypes" [frequencies]="frequencies"></app-edit-main-info>
    
        <app-edit-entry-options *ngIf="showEntryOptions" [(audit)]="audit"></app-edit-entry-options>
    
        <app-edit-questions *ngIf="showQuestions" [(audit)]="audit"></app-edit-questions>
    
        <app-edit-workflow *ngIf="showWorkflow" [(audit)]="audit"></app-edit-workflow>
    
        <div class="pt-8">
            <button class="btn-primary" (click)="saveChanges()">Save</button>
        </div>
        <div class="pt-8">
            <button class="btn-primary" (click)="resetChanges()">Revert Changes</button>
        </div>
    </div>
</div>




