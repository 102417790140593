<div class="container">
    <h1 class="lg:p-8 lg:pb-4 p-2">Dashboard</h1>
    <div class="lg:mx-8 p-2">
        <div class="lg:mx-4 lg:mt-2">
            <div class="flex mb-3">
                <h3 class="mr-4">Actions</h3><span (click)="changeViewActionList()" *ngIf="!showActionList"
                    class="material-symbols-outlined text-3xl align-bottom  self-center text-green-300 cursor-pointer">expand_circle_down</span><span
                    (click)="changeViewActionList()" *ngIf="showActionList"
                    class="material-symbols-outlined text-3xl align-bottom  self-center text-green-300 cursor-pointer">expand_circle_up</span>
            </div>
            <div [ngClass]="{'hidden':!showActionList}" class="transition-all ">
                <div *ngIf="actionList.Actions.length==0 && !actionList.IsError && !actionWaiting">
                    <p>All pending actions complete.</p>
                </div>
                <div *ngIf="actionWaiting">
                    <p>Loading...</p>
                </div>
                <div *ngIf="actionList.IsError">
                    <p class="text-red-600">Unable to load actions at this time, please try again later.</p>
                </div>
                <div *ngIf="actionList.Actions.length>0">
                    <div *ngFor="let action of actionList.Actions">
                        <app-action-panel [action]="action" (Refresh)="refreshActionData()"></app-action-panel>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="lg:mx-8 p-2">
        <div *ngIf="auditList.length>0" class="lg:mx-4 lg:mt-4">
            <div class="flex mb-3">
                <h3 class="mr-4">Current Audits</h3><span (click)="changeViewAuditsList()" *ngIf="!showCurrentAudits"
                    class="material-symbols-outlined text-3xl align-bottom  self-center text-green-300 cursor-pointer">expand_circle_down</span><span
                    (click)="changeViewAuditsList()" *ngIf="showCurrentAudits"
                    class="material-symbols-outlined text-3xl align-bottom  self-center text-green-300 cursor-pointer">expand_circle_up</span>
            </div>
            <div [ngClass]="{'hidden':!showCurrentAudits}" class="transition-all ">
                <app-incomplete-panel [Audits]="auditList" (Refresh)="refreshAudits()"></app-incomplete-panel>
            </div>

        </div>
    </div>
</div>
