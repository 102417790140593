<div class="shadow z-10 fixed w-full inset-y-0 -left-full md:w-1/2 md:-left-1/2 bg-offwhite-100 lg:left-0 lg:shadow-none lg:bg-darkblue-500 lg:h-screen lg:relative lg:right-auto  xl:w-80 lg:w-40 overflow-y-auto lg:block transition-all duration-200 lg:transition-none ios-padding lg:pt-0" [ngClass]="{'menu-in': menuIsOpen}" id="pnlMenu">
    <div class="text-right p-8 pb-2 lg:hidden"><span class="material-symbols-outlined text-green-300 text-4xl cursor-pointer" id="btnCloseMenu" (click)="closeMenu()">close</span></div>
    <div class="flex justify-center" *ngIf="!isBluebird">
        <img src="/assets/mainlogo.webp" aria-label="Care Audits Logo"  class="p-4 pt-2 pb-8 xl:pt-8 block lg:hidden xl:block max-h-48"/>
        <img src="/assets/logo.webp" aria-label="Care Audits Icon" class="p-4 pb-8 pt-8 hidden lg:block xl:hidden max-h-36"/>
    </div>
    <div class="flex justify-center" *ngIf="isBluebird">
        <img src="/assets/bluebirdlogo.png" aria-label="Bluebird Care Logo"  class="p-4 pt-2 pb-8 xl:pt-8 block lg:hidden xl:block max-h-48"/>
        <img src="/assets/bluebirdlogo.png" aria-label="Bluebird Care Logo" class="p-4 pb-8 pt-8 hidden lg:block xl:hidden max-h-36"/>
    </div>
    <ul>
        <li class="border-green-300 border-t hover:bg-green-300 "><a href="/home" class="block p-4 text-green-300 hover:text-standardwhite font-semibold">Home</a></li>
        <li class="border-green-300 border-t hover:bg-green-300"><a href="/startaudit" class="block p-4 text-green-300 hover:text-standardwhite font-semibold">Start Audit</a></li>
        <li class="border-green-300 border-t hover:bg-green-300"><a href="/actions" class="block p-4 text-green-300 hover:text-standardwhite font-semibold">Actions</a></li>
        <li class="border-green-300 border-t hover:bg-green-300"><a href="/diary" class="block p-4 text-green-300 hover:text-standardwhite font-semibold">Diary</a></li>
        <li class="border-green-300 border-t hover:bg-green-300"><a href="/results" class="block p-4 text-green-300 hover:text-standardwhite font-semibold">View Results</a></li>
        <!--<li class="border-green-300 border-t hover:bg-green-300"><a href="/manage" class="block p-4 text-green-300 hover:text-standardwhite font-semibold">Manage Audits</a></li>-->
        <li class="border-green-300 border-t hover:bg-green-300"><a href="/settings" class="block p-4 text-green-300 hover:text-standardwhite font-semibold">Settings</a></li>
        <li class="border-green-300 border-b border-t hover:bg-green-300"><a class="block p-4 text-green-300 hover:text-standardwhite font-semibold hover:cursor-pointer" (click)="signout()">Logout</a></li>
    </ul>
</div>
