import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs';
import { TrialSignupOptions } from 'src/app/core-module/models/Trial/trial-signup-options';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { ToastrService } from 'src/app/core-module/services/toastr.service';
import { UserManagementService } from 'src/app/core-module/services/usermanagement.service';

@Component({
  selector: 'app-trial',
  templateUrl: './trial.component.html',
  styleUrl: './trial.component.scss'
})
export class TrialComponent {

  public showPage1:boolean = true;
  public showPage2:boolean = false;
  public TrialOptions:TrialSignupOptions = new TrialSignupOptions();

  public validators: TrialValidator = new TrialValidator();
  public OrgNameTouched: boolean = false;
  public OrgEmailTouched: boolean = false;
  public OrgNumberTouched: boolean = false;
  public OrgWebTouched: boolean = false;
  public ContactNameTouched: boolean = false;
  public ContactEmailTouched: boolean = false;

  public trialPage: string = "1";
  public Submitting: boolean = false;
  
  constructor(private user:UserManagementService, private toast: ToastrService,public route: ActivatedRoute, public router: Router){

  }

  ngOnInit(){
    this.route.queryParams.subscribe(params => {
      console.log(params);
      if (params['plan']!=null){
        this.TrialOptions.Plan = params['plan'];
      } else {
        this.router.navigateByUrl('https://careaudits.co.uk/trial');
      }
    });

    this.validators.orgName = new FormControl(this.TrialOptions.OrganisationName,[Validators.required,Validators.minLength(5)]);
    this.validators.orgEmail = new FormControl(this.TrialOptions.OrganisationEmail,[Validators.required,Validators.email]);
    this.validators.orgNumber = new FormControl(this.TrialOptions.OrganisationPhone,[Validators.required,Validators.minLength(10),Validators.pattern('^[0-9 ()]*$')]);
    this.validators.orgWeb = new FormControl(this.TrialOptions.OrganisationWebsite,[Validators.required,Validators.minLength(3)]);
    this.validators.contactEmail = new FormControl(this.TrialOptions.ContactEmail,[Validators.required,Validators.email]);
    this.validators.contactName = new FormControl(this.TrialOptions.ContactName,[Validators.required,Validators.minLength(5)]);
  }

  public page2(){
    this.page1ValidateUpdate(5);
    if (!this.validators.isPage1Valid()){
      this.toast.addToastr(new Toastr({Type: 3, Message: "Please complete the required fields."}));
      this.OrgNameTouched = true;
      this.OrgEmailTouched = true;
      this.OrgNumberTouched = true;
      this.OrgWebTouched = true;
      return;
    }

    this.trialPage = "2";
    this.showPage1 = false;
    this.showPage2 = true;
  }

  public page1(){
    this.trialPage = "1";
    this.showPage1 = true;
    this.showPage2 = false;
  }

  createCheckout(){
    this.page1ValidateUpdate(5);
    this.page2ValidateUpdate(3);
    if (!this.validators.isValid()){
      this.toast.addToastr(new Toastr({Type: 3, Message: "Please complete the required fields."}));
      this.OrgNameTouched = true;
      this.OrgEmailTouched = true;
      this.OrgNumberTouched = true;
      this.OrgWebTouched = true;
      this.ContactEmailTouched = true;
      this.ContactNameTouched = true;
      return;
    }

    this.Submitting = true;
   
    this.user.createCheckoutSession(this.TrialOptions).pipe(
      tap((res:string) => {
        if (res.length > 0){
          window.location.href = res;
        } else {
          this.Submitting = false;
          this.toast.addToastr(new Toastr({Type: 3, Message: "An error occurred getting your billing information, please try again later."}));
        }
      })
    ).subscribe();
  }

  page1ValidateUpdate(input: number){
    switch(input){
      case 1:
        this.validators.orgName.setValue(this.TrialOptions.OrganisationName);
        this.OrgNameTouched = true;
        break;
      case 2:
        this.validators.orgEmail.setValue(this.TrialOptions.OrganisationEmail);
        this.OrgEmailTouched = true;
        break;
      case 3:
        this.validators.orgNumber.setValue(this.TrialOptions.OrganisationPhone);
        this.OrgNumberTouched = true;
        break;
      case 4: 
        this.validators.orgWeb.setValue(this.TrialOptions.OrganisationWebsite);
        this.OrgWebTouched = true;
        break;  
      case 5:
        this.validators.orgName.setValue(this.TrialOptions.OrganisationName);
        this.validators.orgEmail.setValue(this.TrialOptions.OrganisationEmail);
        this.validators.orgNumber.setValue(this.TrialOptions.OrganisationPhone);
        this.validators.orgWeb.setValue(this.TrialOptions.OrganisationWebsite);
        break;
    }
  }

  page2ValidateUpdate(input: number){
    switch(input){
      case 1:
        this.validators.contactName.setValue(this.TrialOptions.ContactName);
        this.ContactNameTouched = true;
        break;
      case 2:
        this.validators.contactEmail.setValue(this.TrialOptions.ContactEmail);
        this.ContactEmailTouched = true;
        break;
      case 3: 
        this.validators.contactName.setValue(this.TrialOptions.ContactName);
        this.validators.contactEmail.setValue(this.TrialOptions.ContactEmail);
        break;
    }
  }
}

class TrialValidator {
  orgName: FormControl;
  orgEmail: FormControl;
  orgNumber: FormControl;
  orgWeb: FormControl;
  contactName: FormControl;
  contactEmail: FormControl;
  isValid() {
    return this.orgName.valid && this.orgEmail.valid && this.orgNumber.valid && this.orgWeb.valid && this.contactEmail.valid && this.contactName.valid;
  }
  isPage1Valid(){
    return this.orgName.valid && this.orgEmail.valid && this.orgNumber.valid && this.orgWeb.valid;
  }
}
