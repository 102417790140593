
<div class="h-screen w-screen flex py-8 md:p-0 md:items-center justify-center">
    <div
      class="bg-offwhite-100 rounded-xl center w-11/12 md:w-3/4 shadow-[0_8px_30px_rgb(0,0,0,0.12)]"
    >
      <div class="flex flex-col h-full">
        <div class="flex flex-row h-full">
          <div class="flex flex-col jh-full w-full md:w-3/5 p-4 pr-2">
            <img
              src="/assets/logo.png"
              class="md:hidden max-h-24 mr-4 mt-4 object-scale-down flex justify-self-start"
            />
            
            <div class="flex flex-col justify-center">
                <h1 class="p-4 md:p-8 md:pt-12 pt-16 text-md">Thanks for trying <span class="text-green-300 text-5xl">Care</span> Audits.</h1>
                <p class="p-4 md:px-8 ">Please check your email, we've sent you an email with a link to set a password on your account.</p>
                <p class="p-4 md:px-8 ">Once set, you'll be able to login to your account using your email address as the username and get auditing!</p>
                <p class="p-4 md:px-8 ">If you have any questions or need any help, please <a href="https://careaudits.co.uk/contact" class="underline">contact</a> our helpful support team.</p>
                <p class="p-4 md:px-8 text-sm text-grey-900">Please note, the email should arrive in the next couple of minutes. If you've not received anything after 15 minutes, please check any spam folders and <a href="https://careaudits.co.uk/contact" class="underline">contact</a> us if needed.</p>
                
                <!--<button class="btn-primary disabled" disabled *ngIf="!showNext">Please Wait...</button>
                <button class="btn-primary" *ngIf="showNext" (click)="getStarted()">Get Started ></button>-->
            </div>
            
          </div>
          <div
            class="flex-col justify-between w-2/5 p-4 pl-2 pb-0 bg-offwhite-500 rounded-xl hidden md:flex"
          >
            <img
              src="/assets/logo.png"
              class="p-4 mt-4 center max-h-28 object-scale-down"
            />
            <img src="/assets/tabletuser.png" class="object-bottom" />
          </div>
        </div>
      </div>
    </div>
  </div>
  