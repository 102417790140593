import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuditLocation } from '../../core-module/models/Locations/audit-location';
import { AuditService } from 'src/app/core-module/services/audit.service';
import { Observable, Subscription, fromEvent, pipe, repeat, tap } from 'rxjs';
import { Question } from 'src/app/core-module/models/Audits/question';
import { QuestionHolder } from 'src/app/core-module/models/Audits/question-holder';
import { FlattenedQuestionHolder } from 'src/app/core-module/models/Audits/flattened-question-holder';
import { UserLocationSave } from 'src/app/core-module/models/user-location-save';
import { AuditGuidService } from 'src/app/core-module/services/audits/audit-guid.service';
import { AuthService } from 'src/app/core-module/services/auth.service';
import { LocalStorageService } from 'src/app/core-module/services/local-storage.service';
import { QuestionCompletionInfo } from 'src/app/core-module/models/Audits/question-completion-info';
import { AuditHolder } from 'src/app/core-module/models/Audits/audit-holder';
import { BasicUser } from 'src/app/core-module/models/basic-user';
import { BasicResult } from 'src/app/core-module/models/basic-result';
import { ToastrService } from 'src/app/core-module/services/toastr.service';
import { Toastr } from 'src/app/core-module/models/Utils/toastr';
import { ActionResult } from 'src/app/core-module/models/Compliance-Actions/action-result';

@Component({
  selector: 'app-audit-main',
  templateUrl: './audit-main.component.html',
  styleUrls: ['./audit-main.component.scss'],
})
export class AuditMainComponent {
  test() {
    //console.log(this.currentData);
  }
  location: AuditLocation;
  //audit: FlattenedQuestionHolder;
  //completeAudit: Audit;
  pageReady: boolean = false;
  currentQuestion: Question = new Question();
  currentData: FlattenedQuestionHolder = new FlattenedQuestionHolder();
  currentHeader: string = '';
  submitting: boolean = false;
  submittingIssue: boolean = false;

  //Loading section
  //tempAuditLoad: UserLocationSave = new UserLocationSave();

  //Side Panel
  showSidePanel: boolean = true;
  sidePanelUserChange: boolean = false;

  hasHeader: boolean = false;
  hasMissingQuestions: boolean = false;
  missingQuestions: number = 0;
  missingRequiredQuestions: number = 0;
  missingActions: number = 0;
  showOnlyMissingQuestions: boolean = false;
  showSummaryInformation: boolean = false;

  actionUsers: BasicUser[] = [];


  currentGUID: string = '';
  firstGUID: string = '';
  nextGUID: string = '';
  prevGUID: string = '';

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  actionCounter: number = 0;
  totalScore: number = 0;
  possibleScore: number = 0;
  percentageScore: number = 0;

  constructor(
    private as: AuditService,
    private router: Router,
    private guidService: AuditGuidService,
    private localStorage: LocalStorageService,
    private userService: AuthService,
    private auditService: AuditService,
    private toast: ToastrService
  ) {
    if (this.router.getCurrentNavigation()?.extras.state!['SavedAudit'] != undefined) {
      this.currentData = new FlattenedQuestionHolder(this.router.getCurrentNavigation()?.extras.state!['SavedAudit']);
      this.location = new AuditLocation(this.router.getCurrentNavigation()?.extras.state!['SavedLocation']);
      this.currentQuestion = this.currentData.Questions[0];
      this.isFirstHeader();
      this.setFirstQuestion();
      this.setActionUsers();
    } else {
      this.location = new AuditLocation(this.router.getCurrentNavigation()?.extras.state![
        'location'
      ]);
      this.currentData = new FlattenedQuestionHolder(this.router.getCurrentNavigation()?.extras.state![
        'audit'
      ]);
    }

  }
  ngOnInit() {
    if (this.currentData != undefined) {
      if (this.currentData.AuditID == -1) {
        this.router.navigateByUrl('/home');
      }
      if (this.auditHasData(this.currentData)) {
      } else {
        this.as
          .getAudit(this.currentData.AuditID, this.location.LocationID)
          .pipe(
            tap((res) => {
              if (res.IsError) {
                //console.log('audit-main', res.ErrorCode);
              } else {
                this.currentData = new FlattenedQuestionHolder(res.QuestionHolder);
                this.setGUIDs();
                this.isFirstHeader();
                this.setActionUsers();

              }
              this.pageReady = true;
            })
          )
          .subscribe();
      }

    } else {
      //this.router.navigateByUrl('/home');
    }
    if (this.currentData.AuditID != -1) {
      this.pageReady = true;
    }
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe((evt) => {
      this.checkScreenSize();
    });
    this.checkScreenSize();
  }
  ngOnDestroy() {
    this.resizeSubscription$.unsubscribe();
  }
  setActionUsers() {
    this.actionUsers = [];
    this.currentData.ActionUsers.forEach(e => {
      this.actionUsers.push(new BasicUser(e))
    });
  }
  actionCounting() {
    this.actionCounter = 0;
    this.currentData.Questions.forEach(q => {
      this.actionCounter += q.CompletionInfo.Actions.length
    })
  }
  scoreCalculation() {
    this.possibleScore = 0;
    this.totalScore = 0;
    this.currentData.Questions.forEach(q => {
      if (q.CompletionInfo.QuestionAnswered && !q.NASelected) {
        if (q.QuestionType.ID == 1) {
          this.totalScore += q.QuestionAnswers.find(qa => qa.QuestionAnswerID == q.CompletionInfo.Answer)!.ComplianceScore;
          if (q.MaxScore != -1) {
            this.possibleScore += q.MaxScore
          } else {
            var localMaxScore: number = 0;
            q.QuestionAnswers.forEach(a => {
              if (a.ComplianceScore > localMaxScore) {
                localMaxScore = a.ComplianceScore;
              }
            })
            this.possibleScore += localMaxScore;
          }

        }
      }
    });
    if (this.possibleScore > 0) {
      this.percentageScore = Math.round((this.totalScore / this.possibleScore) * 100);
    }
  }
  getRepeatStartQuestion(QuestionID: number): string {
    return this.currentData.Questions.find(e => e.QuestionID == QuestionID)!.QuestionHeader
  }
  createNewQuestionStructure(Questions: QuestionHolder[]) {
    for (let e of Questions) {
      if (e.Question.QuestionID > 0) {
        this.currentData.Questions.push(e.Question);
      } else {
        this.createNewQuestionStructure(e.Sections);
      }
    }
  }
  isFirstHeader() {
    if (this.currentData.Questions[0].QuestionType.ID == 6) {
      this.hasHeader = true;
      this.currentHeader = this.currentData.Questions[0].QuestionHeader;
    }
  }
  showHeader() {
    if (this.hasHeader) {
      if (this.currentQuestion.QuestionType.ID != 6) {
        return true;
      }
    }
    return false;
  }
  setHeader() {
    if (this.currentQuestion.QuestionType.ID == 6) {
      this.currentHeader = this.currentQuestion.QuestionHeader;
      this.hasHeader = true;
    } else {
      var newHeader = this.searchForHeader();
      if (newHeader == '') {
        this.hasHeader == false;
      } else {
        this.currentHeader = newHeader;
        this.hasHeader == true;
      }
    }
  }
  searchForHeader(): string {
    var currentIndex = this.currentData.Questions.findIndex((e) => {
      return e.GUID == this.currentQuestion.GUID;
    });
    var newHeading = '';
    for (let i = 0; i < currentIndex; i++) {
      if (this.currentData.Questions[i].QuestionType.ID == 6) {
        newHeading = this.currentData.Questions[i].QuestionHeader;
      }
    }
    return newHeading;
  }
  showHeadingSection() {
    return this.showLocation() && this.showAuditName();
  }
  showLocation(): boolean {
    if (this.location != undefined) {
      return true;
    }
    return false;
  }
  showAuditName(): boolean {
    if (this.currentData != undefined && this.currentData.AuditName.length > 0) {
      return true;
    }
    return false;
  }
  setGUIDs() {
    this.guidService.setGUIDs(this.currentData.Questions);
    this.guidService.setBranchGUIDs(this.currentData.Questions);
    this.setFirstQuestion();
  }
  setFirstQuestion() {
    for (let i = 0; i < this.currentData.Questions.length; i++) {
      if (i == 0) {
        this.currentQuestion = this.currentData.Questions[i];
        if (this.currentQuestion.QuestionType.ID != 6) {
          this.firstGUID = this.currentQuestion.GUID;
        }
      } else {
        if (this.firstGUID == '') {
          if (this.currentData.Questions[i].QuestionType.ID != 6) {
            this.firstGUID = this.currentData.Questions[i].GUID;
          }
        }
      }
    }
    this.nextGUID = this.currentQuestion.NextQ;
    this.prevGUID = this.currentQuestion.PrevQ;
  }
  nextQuestion() {
    var previousGUID = this.currentQuestion.GUID;
    var branched = false;
    if (this.currentQuestion.NextQ == '-1') {
      this.goToSummary();
      //no next q
    } else {
      if (this.showOnlyMissingQuestions) {
        this.setNextMissingQuestion(this.currentQuestion.NextQ);

      } else {
        if (this.currentQuestion.IsBranching) {
          if (this.currentQuestion.CompletionInfo.QuestionAnswered == false) {
            //cannot continue
          } else {
            branched = true;
            // set all current branched to branched out to false
            // set all other related to the question to branchedout true
            var nextBranchGUID: string = "";
            this.currentQuestion.QuestionAnswers.forEach(e => {
              if (e.QuestionAnswerID == this.currentQuestion.CompletionInfo.Answer) {
                e.BranchGUIDs.forEach(bg => {
                  this.currentData.Questions.forEach(q => {
                    if (q.GUID == bg) {
                      if (nextBranchGUID == "") {
                        nextBranchGUID = bg;
                      }
                      q.BranchedOut = false;
                    }
                  })
                })
              } else {
                e.BranchGUIDs.forEach(bg => {
                  this.currentData.Questions.forEach(q => {
                    if (q.GUID == bg) {
                      q.BranchedOut = true;
                    }
                  })
                })
              }

            })
            this.updateLocal();
            this.currentQuestion = this.guidService.getFlattenedQuestionByGUID(
              nextBranchGUID,
              this.currentData.Questions
            );

          }
        } else {
          //check if next is valid, if not cycle past
          var validatatedGUID = this.getValidNext(this.nextGUID);
          this.currentQuestion = this.guidService.getFlattenedQuestionByGUID(
            validatatedGUID,
            this.currentData.Questions
          );
        }
        this.nextGUID = this.currentQuestion.NextQ;

        this.prevGUID = previousGUID;

      }
    }
    this.setHeader();
  }
  getValidNext(GUID: string): string {
    //return GUID;
    var returnValue = "-1";
    this.currentData.Questions.forEach(q => {
      if (returnValue == "-1") {
        if (q.GUID == GUID) {
          if (q.BranchedOut) {
            returnValue = this.getValidNext(q.NextQ)
          } else {
            returnValue = q.GUID;
          }
        }
      }

    })
    return returnValue;
  }

  setNextMissingQuestion(GUID: string) {

    this.currentQuestion = this.currentData.Questions.find(
      (e) => e.GUID == this.findNextMissingQuestion(GUID)
    )!;
  }
  findNextMissingQuestion(questionGUID: string): string {
    var currentQuestion = this.guidService.getFlattenedQuestionByGUID(
      questionGUID,
      this.currentData.Questions
    );
    if (
      !currentQuestion.CompletionInfo.QuestionAnswered &&
      currentQuestion.QuestionType.ID != 6
    ) {
      return questionGUID;
    } else {
      if (currentQuestion.NextQ == '-1') {
        return '-1';
        //show finish
      } else {
        return this.findNextMissingQuestion(currentQuestion.NextQ);
      }
    }
  }
  prevQuestion() {
    if (this.currentQuestion.PrevQ == '-1') {
      //no prev q
    } else {
      if (this.showOnlyMissingQuestions) {
        //this.findPreviousMissingQuestion(this.currentQuestion.PrevQ);
      } else {
        var validGuid = this.getValidPrevious(this.currentQuestion.PrevQ);
        this.currentQuestion = this.guidService.getFlattenedQuestionByGUID(
          validGuid,
          this.currentData.Questions
        );
        this.prevGUID = this.currentQuestion.PrevQ;
        this.nextGUID = this.currentQuestion.NextQ;
      }
    }
    this.setHeader();
  }
  getValidPrevious(GUID: string): string {
    var returnValue = "-1";
    this.currentData.Questions.forEach(q => {
      if (returnValue == "-1") {
        if (q.GUID == GUID) {
          if (q.BranchedOut) {
            returnValue = this.getValidPrevious(q.PrevQ)
          } else {
            returnValue = q.GUID;
          }
        }
      }

    })
    return returnValue;
  }
  jumpToQuestion(GUID: string) {
    this.showSummaryInformation = false;
    this.hasMissingQuestions = false;
    this.currentQuestion = this.guidService.getFlattenedQuestionByGUID(
      GUID,
      this.currentData.Questions
    );
    this.nextGUID = this.currentQuestion.NextQ;
    this.prevGUID = this.currentQuestion.PrevQ;
    this.checkAutoCloseSidePanel();
    this.setHeader();
  }
  goToSummary() {
    this.showSummaryInformation = true;
    this.validateFlattenedQuestions();
    this.actionCounting();
    this.scoreCalculation();
    this.setHeaderToAuditName();
  }
  returnFromSummary() {
    this.showSummaryInformation = false;
  }
  repeatSection() {
    if (this.currentQuestion.IsRepeatEnd) {
      this.insertNewSection(this.currentQuestion);
    }
  }
  insertNewSection(repeatQuestion: Question) {
    var newRepeatEnd: Question = Object.assign({}, repeatQuestion);
    newRepeatEnd.GUID = crypto.randomUUID();
    newRepeatEnd.NextQ = repeatQuestion.NextQ;
    newRepeatEnd.CompletionInfo = new QuestionCompletionInfo();
    var prevQuestion = repeatQuestion.GUID;
    var nextQuestion = repeatQuestion.NextQ;
    var newQuestionArray: Question[] = [];
    for (var i: number = 0; i < repeatQuestion.RepeatQuestionIDs.length; i++) {
      var newQuestion: Question = Object.assign(
        {},
        this.currentData.Questions.find(e => { return e.QuestionID == repeatQuestion.RepeatQuestionIDs[i] })

      );
      newQuestion.GUID = crypto.randomUUID();
      if (i == 0) {
        newQuestion.PrevQ = prevQuestion;
      }
      if (i == repeatQuestion.RepeatQuestionIDs.length - 1) {
        newQuestion.NextQ = newRepeatEnd.GUID;
      }
      newQuestion.CompletionInfo = new QuestionCompletionInfo();
      newQuestionArray.push(newQuestion);

    }
    for (var i: number = 0; i < newQuestionArray.length; i++) {
      if (i < newQuestionArray.length - 1) {
        newQuestionArray[i].NextQ = newQuestionArray[i + 1].GUID;
      }
      if (i != 0) {
        newQuestionArray[i].PrevQ = newQuestionArray[i - 1].GUID;
      }
      if (i == 0) {
        this.currentQuestion = newQuestionArray[i];
      }
      this.currentData.Questions.push(newQuestionArray[i]);
    }
    this.currentData.Questions.push(newRepeatEnd);
    this.currentData.Questions.find((e) => {
      return e.GUID == prevQuestion;
    })!.NextQ = newQuestionArray[0].GUID;
    //set the prev guid of the question following the insert
    this.currentData.Questions.find((e) => {
      return e.GUID == nextQuestion;
    })!.PrevQ = newQuestionArray[newQuestionArray.length - 1].GUID;
    this.orderFlattenedQuestions();
  }
  updateAnswer(e: any) {
    this.currentQuestion.CompletionInfo.Answer = e;
    this.updateLocal();
  }
  triggerLocalSave() {
    this.updateLocal();
  }
  updateValidAnswerState(e: boolean) {
    this.currentQuestion.CompletionInfo.QuestionAnswered = e;
  }
  updateCurrentQuestion(q: Question) {
    this.currentQuestion.CompletionInfo = structuredClone(q).CompletionInfo;
    this.updateLocal();
  }
  updateLocal() {
    var username = '';
    this.userService.getUserName()
      .pipe(
        tap((a) => {
          username = a.Username;
        })
      )
      .subscribe();
    if (username == null) {
      return;
    }
    var localData: any;
    this.auditService.getLocalAudits()
      .pipe(
        tap((a) => {
          localData = a;
        })
      )
      .subscribe();
    if (localData == null) {
      var ls: UserLocationSave = {
        Username: username,
        Location: this.location,
        FlattenedQuestions: [],
      };
      ls.FlattenedQuestions.push(this.currentData);
      var saveList: UserLocationSave[] = [];
      saveList.push(ls);
      this.localStorage.setData(2, saveList);
    } else {
      var parsedData: UserLocationSave[] = <UserLocationSave[]>localData;
      var currentSet: UserLocationSave = new UserLocationSave();
      var localDataIndex = parsedData.findIndex((e) => {
        return e.Username == username && e.Location.LocationID == this.location.LocationID && e.FlattenedQuestions[0].AuditID == this.currentData.AuditID;
      });
      if (localDataIndex != -1) {
        currentSet = parsedData.find((f) => {
          return f.Username == username && f.Location.LocationID == this.location.LocationID && f.FlattenedQuestions[0].AuditID == this.currentData.AuditID;
        })!;
        if (currentSet.FlattenedQuestions.some((g) => { return g.AuditID == this.currentData.AuditID; })) {
          var tempQuestions: Question[] = [];
          this.currentData.Questions.forEach(e => {
            tempQuestions.push(new Question(e));
          })
          currentSet.FlattenedQuestions.find((h) => {
            return h.AuditID == this.currentData.AuditID;
          })!.Questions = tempQuestions;
        } else {
          currentSet = {
            Username: username,
            Location: this.location,
            FlattenedQuestions: [
              new FlattenedQuestionHolder({
                AuditID: this.currentData.AuditID,
                AuditName: this.currentData.AuditName,
                Questions: this.currentData.Questions,
                ActionUsers: this.actionUsers
              }),
            ],
          };
          parsedData.push(currentSet);
        }
      } else {
        currentSet = {
          Username: username,
          Location: this.location,
          FlattenedQuestions: [
            new FlattenedQuestionHolder({
              AuditID: this.currentData.AuditID,
              AuditName: this.currentData.AuditName,
              Questions: this.currentData.Questions,
              ActionUsers: this.actionUsers
            }),
          ],
        };
        parsedData.push(currentSet);
      }
      this.localStorage.setData(2, parsedData);
    }
  }
  validateAudit() {
    this.submitting = true;
    if (!this.validateFlattenedQuestions()) {
      this.hasMissingQuestions = true;
      this.submitting = false;
    } else {
      this.saveAudit()
    }
  }
  validateFlattenedQuestions(): boolean {
    var returnValue: boolean = true;
    this.missingQuestions = 0;
    this.missingRequiredQuestions = 0;
    this.missingActions = 0;
    this.currentData.Questions.forEach((e) => {
      if (!e.CompletionInfo.QuestionAnswered && e.QuestionType.ID != 6 && !e.NASelected && !e.BranchedOut) {
        this.missingQuestions++;
        if (e.Required) {
          returnValue = false;
          this.missingRequiredQuestions++;
        }
      }
      if (e.CompletionInfo.QuestionAnswered && e.CompletionInfo.NonCompliant && (e.CompletionInfo.Actions.length == 0 || e.CompletionInfo.Actions.length == undefined)) {
        this.missingActions++;
        returnValue = false;
      }
    });
    return returnValue;
  }
  saveAudit() {
    var AH: AuditHolder = new AuditHolder();
    AH.LocationID = this.location.LocationID;
    AH.Audit = this.currentData;
    AH.Audit.AuditCompletionInfo.SubmittedDate = new Date();
    this.auditService.saveAudit(AH).pipe(
      tap((a) => {
        this.auditSuccess(a);
      })
    )
      .subscribe();
  }
  auditSuccess(BR: ActionResult) {
    if (BR.IsError == false) {
      var username = this.getUsername();
      this.auditService.removeLocalAudit(this.currentData.AuditID, this.location.LocationID, username);
      this.toast.addToastr(new Toastr({ Type: 2, Message: "Audit submitted." }));
      this.router.navigateByUrl('/home', { state: { "submitactions": BR } });
    } else {
      this.submitting = false;
      this.toast.addToastr(new Toastr({ Type: 3, Message: "Unable to submit audit, please try again." }));
      this.submittingIssue = true;
    }
  }
  getUsername(): string {
    var username: string = "";
    this.userService.getUserName()
      .pipe(
        tap((a) => {
          username = a.Username;
        })
      )
      .subscribe();
    return username;

  }
  orderFlattenedQuestions() {
    this.currentData.Questions = this.guidService.orderFlattenedQuestions(this.currentData.Questions);
  }
  showOnlyMissing() {
    this.showOnlyMissingQuestions = true;
    this.showSummaryInformation = false;
    this.setNextMissingQuestion(this.findNextMissingQuestion(this.firstGUID));
    this.setHeader();
    this.hasMissingQuestions = false;
  }
  switchShowSidePanel() {
    if (this.showSidePanel) {
      this.showSidePanel = false;
    } else {
      this.showSidePanel = true;
    }
    this.sidePanelUserChange = true;
  }
  checkScreenSize() {
    if (window.innerWidth < 1024) {
      this.showSidePanel = false;
    } else if (this.sidePanelUserChange == false) {
      this.showSidePanel = true;
    }
  }
  checkAutoCloseSidePanel() {
    if (window.innerWidth < 1024 && this.showSidePanel) {
      this.showSidePanel = false;
    }
  }
  setHeaderToAuditName() {
    this.currentHeader = this.currentData.AuditName;
  }
  auditHasData(FQH: FlattenedQuestionHolder): boolean {
    var counter = 0;
    FQH.Questions.forEach(e => {
      if (e.CompletionInfo.Answer != null) {
        counter++;
      }
    })
    return counter > 0;
  }
}
