import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlattenedQuestionHolder } from 'src/app/core-module/models/Audits/flattened-question-holder';
import { DDL } from 'src/app/core-module/models/ddl';


@Component({
  selector: 'app-edit-main-info',
  templateUrl: './edit-main-info.component.html',
  styleUrls: ['./edit-main-info.component.scss']
})
export class EditMainInfoComponent {
  public _auditTypes: DDL[] = [];
  public _frequencies: DDL[] = [];
  @Input() public audit: FlattenedQuestionHolder;
  @Input() set auditTypes(auditDDL: DDL[]) {
    this._auditTypes = [];
    this._auditTypes.push(new DDL({"Description":"Please select...","ID":-1}))
    if (auditDDL) {
      auditDDL.forEach(x => {
        this._auditTypes.push(new DDL(x));
      })
    }
  };
  @Input() set frequencies(frequencyDDL: DDL[]){
    this._frequencies = [];
    this._frequencies.push(new DDL({"Description":"Please select...","ID":-1}))
    if (frequencyDDL) {
      frequencyDDL.forEach(x => {
        this._frequencies.push(new DDL(x));
      })
    }
  }
  @Output() auditChange: EventEmitter<FlattenedQuestionHolder> = new EventEmitter<FlattenedQuestionHolder>();

  
  public _audit: FlattenedQuestionHolder;

  auditChanged() {
    if(this.audit.AuditInfo.AuditType.ID!=-1){
      this._auditTypes.find(x=>x.ID==-1)!.Active = false;
    };
    this.auditChange.emit(this.audit);
  }

  auditTypeComparison(option: DDL, value: DDL): boolean {
    if(!value){     
      //this._auditTypes.push(new DDL({"Description":"Please select...","ID":-1})); 
      return false;
    }
    return option.ID == value.ID;
  }
}
