import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  ObservableInput,
  catchError,
  map,
  of,
  tap,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocationResult } from '../models/Locations/location-result';
import { LocationRequest } from '../models/Locations/location-request';
import { add, constructNow, isWithinInterval, parseISO } from 'date-fns';
import { LocalStorageService } from './local-storage.service';
import { DDL } from '../models/ddl';
import { AuditLocation } from '../models/Locations/audit-location';
import { FrequencyResponse } from '../models/Utils/frequency-response';

@Injectable({
  providedIn: 'root',
})
export class ReferenceService {
  //User variables
  public minUsernameLength:number = 6;

  //localstorage
  private _localChecks: { ID: string; Checked: Date }[] = [];

  private _locationByAuditID: string;

  private _locations = new BehaviorSubject<LocationResult>(null as any);
  public locations = this._locations.asObservable();

  constructor(
    private httpClient: HttpClient,
    private localStorage: LocalStorageService
  ) { }
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    observe: 'response' as 'response',
    responseType: 'json' as const,
    withCredentials: true,
  };
  getLocationsByAuditID(AuditID:number,FeatureID:number): Observable<any> {
    var lastCheck: Date = this.getLocalTiming('AuditID');
    if (!this.hasRecentUpdate(lastCheck)) {
      return this._getLocationsByAuditID(AuditID,FeatureID)
        .pipe(
          tap((res) => {
            this._locations.next(res.body!);
            this.localStorage.setData(4, res.body!);
            this.updateTimingCheck('AuditID');
          }),
          catchError(this._getLocalLocationsByAuditID)
        );
    } else {
      return this._getLocalLocationsByAuditID(null, null);
    }
  }
  private _getLocalLocationsByAuditID(
    err: any,
    caught: any
  ): Observable<any> {
    this.localStorage.loadData(4);
    return this.localStorage.observables.localRef
      .find((e) => {
        return e.ID == 'locations';
      })!
      .Obs
  }
  private updateTimingCheck(ID: string) {
    if (this._localChecks == null) {
      this._localChecks = [];
    }
    var indexCheck = this._localChecks.findIndex((e) => e.ID == ID);
    if (indexCheck >= 0) {
      this._localChecks = this._localChecks.splice(indexCheck, 1);
    }
    this._localChecks.push({ ID: ID, Checked: constructNow(new Date()) });
    this.localStorage.updateData(3, this._localChecks);
  }

  private _getLocationsByAuditID(AuditID: number, FeatureID:number) {
    let req = new LocationRequest({'AuditID':AuditID, 'CustomerID':-1, 'FeatureID':FeatureID});
    return this.httpClient.post<LocationResult>(
      `${environment.apiUrl}locations/getlocationsbyauditid`,
      req,
      this.httpOptions
    );
  }
  getLocationForUser():Observable<LocationResult[]>{
    return this.httpClient.get<LocationResult[]>(`${environment.apiUrl}locations/getlocations`, this.httpOptions).pipe(
      map(res => {
        if(res.body!=null){
          var returnList:LocationResult[] = [];
          res.body.forEach(e=>{
            returnList.push(new LocationResult(e));
          })
          return returnList;
        }
        return [];
      })
    );
  }
  getFlatLocationsForUser():Observable<AuditLocation[]>{
    return this.httpClient.get<LocationResult[]>(`${environment.apiUrl}locations/getflatlocations`, this.httpOptions).pipe(
      map(res => {
        if(res.body!=null){
          var returnList:AuditLocation[] = [];
          res.body.forEach(e=>{
            returnList.push(new AuditLocation(e));
          })
          return returnList;
        }
        return [];
      })
    );
  }
  /*
  this.localStorageService.loadData(1);
  return this.localStorageService.observables.localRef.find((e) => {
    return e.ID == 'userSettings';
  })!.Obs;*/
  getLocalTiming(timingName: string): Date {
    //var data:{ID:string,Checked:Date}[] = []
    this.localStorage.loadData(3);
    this.localStorage.observables.localRef
      .find((e) => {
        return e.ID == 'timeChecks';
      })!
      .Obs.pipe(
        tap((a) => {
          this._localChecks = a;
        })
      )
      .subscribe();
    var minDate: Date = new Date(1900, 1, 1);
    if (this._localChecks == null) {
      return minDate;
    }
    if (this._localChecks.length == 0) {
      return minDate;
    }
    var index = this._localChecks.findIndex((e) => {
      return e.ID == timingName;
    });
    if (index == -1) {
      return minDate;
    }
    return parseISO(
      this._localChecks
        .find((e) => {
          return e.ID == timingName;
        })!
        .Checked.toString()
    );
  }

  hasRecentUpdate(check: Date): boolean {
    return isWithinInterval(check, {
      start: add(constructNow(new Date()), { minutes: -5 }),
      end: constructNow(new Date()),
    });
  }

  getFrequencies():Observable<DDL[]>{

    return this.httpClient.post<FrequencyResponse>(`${environment.apiUrl}lookup/frequencies`, false, this.httpOptions).pipe(
      map(res => {
        return res.body!.Results;
      })
    );
    /*
    let temp: DDL[] = [];
    temp.push(new DDL({"ID": 1, "Description": "One Time"}));
    temp.push(new DDL({"ID": 2, "Description": "Weekly"}));
    temp.push(new DDL({"ID": 3, "Description": "4-Weekly"}));
    temp.push(new DDL({"ID": 4, "Description": "Monthly"}));
    temp.push(new DDL({"ID": 5, "Description": "Quarterly"}));
    temp.push(new DDL({"ID": 6, "Description": "Semi-Annually"}));
    temp.push(new DDL({"ID": 7, "Description": "Annually"}));
    return of(temp);
    */
  }

  getMonths():Observable<DDL[]>{
    let temp: DDL[] = [];
    temp.push(new DDL({ID: 0, Description: "January"}));
    temp.push(new DDL({ID: 1, Description: "February"}));
    temp.push(new DDL({ID: 2, Description: "March"}));
    temp.push(new DDL({ID: 3, Description: "April"}));
    temp.push(new DDL({ID: 4, Description: "May"}));
    temp.push(new DDL({ID: 5, Description: "June"}));
    temp.push(new DDL({ID: 6, Description: "July"}));
    temp.push(new DDL({ID: 7, Description: "August"}));
    temp.push(new DDL({ID: 8, Description: "September"}));
    temp.push(new DDL({ID: 9, Description: "October"}));
    temp.push(new DDL({ID: 10, Description: "November"}));
    temp.push(new DDL({ID: 11, Description: "December"}));
    return of(temp);
  }

  
}