import { Routes } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { StartAuditComponent } from "./start-audit/start-audit.component";
import { SelectLocationComponent } from "./select-location/select-location.component";
import { AuditMainComponent } from "./audit-main/audit-main.component";
import { TestpageComponent } from "./testpage/testpage.component";
import { SettingsComponent } from "./settings/settings.component";
import { SelectAuditEditComponent } from "./settings/manageaudits/select-audit-edit/select-audit-edit.component";
import { EditAuditComponent } from "./settings/manageaudits/edit-audit/edit-audit.component";
import { ActionsComponent } from "./actions/actions.component";
import { ActionDetailComponent } from "./action-detail/action-detail.component";
import { DiaryComponent } from "./diary/diary.component";
import { ResultsHomeComponent } from "./results-home/results-home.component";
import { ExistingUsersComponent } from "./settings/manageusers/existing-users/existing-users.component";
import { GroupManagementComponent } from "./settings/manageusers/group-management/group-management.component";
import { NewUserComponent } from "./settings/manageusers/new-user/new-user.component";
import { AssignAuditsComponent } from "./settings/manageaudits/assign-audits/assign-audits.component";
import { AssignLocationsComponent } from "./settings/managelocations/assign-locations/assign-locations.component";
import { EditLocationComponent } from "./settings/managelocations/edit-location/edit-location.component";
import { CurrentUsersComponent } from "./settings/manageusers/current-users/current-users.component";
import { UserProfileComponent } from "./settings/manageusers/user-profile/user-profile.component";
import { UserAuditsComponent } from "./settings/manageusers/user-audits/user-audits.component";

export const SECURE_ROUTES: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'home', component: HomeComponent },
    { path: 'startaudit', component: StartAuditComponent },
    { path: 'actions', component: ActionsComponent },
    { path: 'action', component: ActionDetailComponent },
    { path: 'selectlocation', component: SelectLocationComponent },
    { path: 'audit', component: AuditMainComponent },
    { path: 'settings', component: SettingsComponent },
    { path: 'results', component: ResultsHomeComponent },
    { path: 'settings/manage/edit', component: SelectAuditEditComponent },
    { path: 'settings/manage/edit/audit', component: EditAuditComponent },
    { path: 'settings/manage/new/audit', component: EditAuditComponent },
    { path: 'settings/manage/assignaudit', component: AssignAuditsComponent },
    { path: 'settings/users/existing', component: ExistingUsersComponent },   
    { path: 'settings/users/usermanagement', component: CurrentUsersComponent },
    { path: 'settings/users/existinggroups', component: ExistingUsersComponent },
    { path: 'settings/users/profile', component: UserProfileComponent },
    { path: 'settings/users/groups', component: GroupManagementComponent },
    { path: 'settings/users/newuser', component: NewUserComponent },
    { path: 'settings/users/newgroup', component: NewUserComponent },
    { path: 'settings/users/useraudits', component: UserAuditsComponent },
    { path: 'settings/users/groupaudits', component: UserAuditsComponent },
    { path: 'settings/locations/assignlocations', component: AssignLocationsComponent },
    { path: 'settings/locations/assigngrouplocations', component: AssignLocationsComponent },
    { path: 'settings/locations/editlocation', component: EditLocationComponent },
    { path: 'settings/locations/newlocation', component: EditLocationComponent },
    { path: 'diary', component: DiaryComponent },
    { path: 'test', component: TestpageComponent }
];