import { DDL } from "../ddl";

export class AuditInfo{
    public Code: string;
    public RevisionDate: string;
    public RevisionNotes: string;
    public ReportDescription:string;
    public AuditType: DDL;
    public Frequency: DDL;
    constructor(values: Object = {}){
        Object.assign(this, values);   
        if(values.hasOwnProperty('Audit')){
            this.AuditType = new DDL((values as AuditInfo)['AuditType'] as DDL) 
         }  
    }
}