import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { AuditHolder } from 'src/app/core-module/models/Audits/audit-holder';
import { FlattenedQuestionHolder } from 'src/app/core-module/models/Audits/flattened-question-holder';
import { ActionHolder } from 'src/app/core-module/models/Compliance-Actions/action-holder';
import { ActionListRequest } from 'src/app/core-module/models/Compliance-Actions/action-list-request';
import { ActionResult } from 'src/app/core-module/models/Compliance-Actions/action-result';
import { AuditLocation } from 'src/app/core-module/models/Locations/audit-location';
import { UserLocationSave } from 'src/app/core-module/models/user-location-save';
import { AuditService } from 'src/app/core-module/services/audit.service';
import { AuditValidationService } from 'src/app/core-module/services/audits/audit-validation.service';
import { AuthService } from 'src/app/core-module/services/auth.service';
import { ComplianceActionService } from 'src/app/core-module/services/compliance-action.service';
import { LocalStorageService } from 'src/app/core-module/services/local-storage.service';
import { UtilService } from 'src/app/core-module/services/util.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  constructor(private localStorage: LocalStorageService, private userService: AuthService, private auditService: AuditService, private router: Router, public util: UtilService, public auditValidation: AuditValidationService, public actionService: ComplianceActionService) { 
    if(this.router.getCurrentNavigation()?.extras.state != undefined){
      if (this.router.getCurrentNavigation()?.extras.state!['submitactions'] != undefined) {
          this.actionList = new ActionResult(this.router.getCurrentNavigation()?.extras.state!['submitactions']);
          this.orderActionList();        
          this.actionWaiting = false;
      }
    }
    else{
      this.getActionData();
    }
    
    
  }

  auditList: UserLocationSave[] = [];
  actionList: ActionResult = new ActionResult();
  username: string = '';

  public showActionList:boolean = true;
  public showCurrentAudits:boolean = true;
  
  public actionWaiting:boolean = true;

  ngOnInit() {
    this.getUserData();
    this.getLocalData();
  }


  getUserData() {
    this.userService.getUserName()
      .pipe(
        tap((a) => {
          this.username = a.Username;
        })
      )
      .subscribe();
    if (this.username == null) {
      return;
    }
  }
  getLocalData() {
    var auditData: UserLocationSave[] = [];
    this.auditList = [];
    this.auditService.getLocalAudits()
      .pipe(
        tap((a) => {
          auditData = a;
        })
      )
      .subscribe();
    if (auditData != null) {
      for (let i = 0; i < auditData.length; i++) {
        if (auditData[i].Username == this.username) {
          this.auditList.push(auditData[i]);
        }
      }
    }
  }
  refreshAudits(){
    this.getLocalData();
  }
  refreshActionData(){
    this.getActionData();
  }
  getActionData(){
    var request = new ActionListRequest();
    request.DashboardRequest = true;
        this.actionService.getCurrentActions(request).pipe(
      tap(res => {
        
        if(res.IsError){
        }else{
            this.actionList = res;
            this.orderActionList();
        }
        this.actionWaiting = false;
      })
    ).subscribe();
  }

  changeViewActionList(){
    this.showActionList = !this.showActionList;
  }
  changeViewAuditsList(){
    this.showCurrentAudits = !this.showCurrentAudits;
  }
  orderActionList(){
    this.actionList.Actions = this.actionList.Actions.sort((a,b) =>{return <any>new Date(a.ActionDeadline) - <any>new Date(b.ActionDeadline)});
  }

}
