<div class="h-screen w-screen flex py-8 md:p-0 md:items-center justify-center">
    <div
      class="bg-offwhite-100 rounded-xl center w-11/12 md:w-3/4 shadow-[0_8px_30px_rgb(0,0,0,0.12)]"
    >
      <div class="flex flex-col h-full">
        <div class="flex flex-row h-full">
          <div class="flex flex-col jh-full w-full md:w-3/5 p-4 pr-2">
            <img
              src="/assets/logo.png"
              class="md:hidden max-h-24 mr-4 mt-4 object-scale-down flex justify-self-start"
            />
            
            <div class="flex flex-col justify-center">
                <div class="flex justify-between items-baseline">
                  <h1 class="p-4 md:p-8 md:pt-12 pt-16">Trial Signup</h1>
                  <p class="text-xs pr-8">Step {{trialPage}} of 3</p>
                </div>
                
                <div class="md:px-8 px-4 pb-4" *ngIf="showPage1">
                  <p class="text-sm">In order to get the system ready for you, please complete some details about your organisation below:</p>
                    <label class="block pt-4">
                        <span class="">Organisation Name <span class="text-red-600">*</span></span>
                        <input type="text" [(ngModel)]="TrialOptions.OrganisationName"(keyup)="page1ValidateUpdate(1)" class="text-box" [ngClass]="{'error':!validators.orgName.valid && OrgNameTouched}" />
                    </label>
                    <label class="block pt-4">
                        <span class="">Organisation Email <span class="text-red-600">*</span></span>
                        <input type="email" [(ngModel)]="TrialOptions.OrganisationEmail" (keyup)="page1ValidateUpdate(2)" class="text-box"  [ngClass]="{'error':!validators.orgEmail.valid && OrgEmailTouched}" />
                    </label>
                    <label class="block pt-4">
                        <span class="">Organisation Contact Number <span class="text-red-600">*</span></span>
                        <input type="tel" [(ngModel)]="TrialOptions.OrganisationPhone" (keyup)="page1ValidateUpdate(3)" class="text-box"  [ngClass]="{'error':!validators.orgNumber.valid && OrgNumberTouched}" />
                    </label>
                    <label class="block pt-4">
                        <span class="">Organisation Website <span class="text-red-600">*</span></span>
                        <input type="text" [(ngModel)]="TrialOptions.OrganisationWebsite" (keyup)="page1ValidateUpdate(4)" class="text-box"  [ngClass]="{'error':!validators.orgWeb.valid && OrgWebTouched}" />
                    </label>
                    <div class="flex justify-end">
                        <button class="btn-primary mt-6" (click)="page2()">Next ></button>
                    </div>     
                </div>
                <div class="md:px-8 px-4 pb-4" *ngIf="showPage2">
                  <p class="text-sm">To set up your account, please enter your contact details below:</p>
                    <label class="block pt-4">
                        <span class="">Contact Name <span class="text-red-600">*</span></span>
                        <input type="text" [(ngModel)]="TrialOptions.ContactName" (keyup)="page2ValidateUpdate(1)" class="text-box" [ngClass]="{'error':!validators.contactName.valid && ContactNameTouched, 'disabled':Submitting}" [disabled]="Submitting"/>
                    </label>
                    <label class="block pt-4">
                        <span class="">Contact Email <span class="text-red-600">*</span></span>
                        <input type="email" [(ngModel)]="TrialOptions.ContactEmail" (keyup)="page2ValidateUpdate(2)" class="text-box" [ngClass]="{'error':!validators.contactEmail.valid && ContactEmailTouched, 'disabled':Submitting}" [disabled]="Submitting"/>
                    </label>                   
                    <div class="flex justify-between">
                        <button class="btn-secondary mt-6 text-sm" (click)="page1()" [disabled]="Submitting"  [ngClass]="{'disabled':Submitting}">< Back</button>
                        <button class="btn-primary mt-6" (click)="createCheckout()" [disabled]="Submitting" [ngClass]="{'disabled':Submitting}">
                          <span *ngIf="!Submitting">Next ></span>
                          <span *ngIf="Submitting">Processing...</span>
                        </button>
                    </div>     
                </div>

            </div>
            
          </div>
          <div
            class="flex-col justify-between w-2/5 p-4 pl-2 pb-0 bg-offwhite-500 rounded-xl hidden md:flex"
          >
            <img
              src="/assets/logo.png"
              class="p-4 mt-4 center max-h-28 object-scale-down"
            />
            <img src="/assets/tabletuser.png" class="object-bottom" />
          </div>
        </div>
      </div>
    </div>
  </div>
  